<template>
    <v-app-bar 
        :color="color"
        :flat="flat"
        :class="{ expand: flat }"
        flat 
        color="transparent" 
        height="100" 
        style="display: inline-block; z-index: 99; position: fixed;"
    >
         <v-img                                
            :src="require('../assets/logo5.png')"                                
            contain                                
            class="imgs"
        />

        <v-app-bar dark style="z-index: 99;" density="compact" height="50" width="150" class="rounded-pill hexa">
          <v-tabs
            centered
            color="grey-darken-2"
          >
            <v-tab href="/">HOME</v-tab>
            <v-tab @click="$vuetify.goTo('#services')">SERVICES</v-tab>
            <v-tab @click="$vuetify.goTo('#roadmap')">ROADMAP</v-tab>
            <v-tab @click="$vuetify.goTo('#product')">PRODUCT</v-tab>
          </v-tabs>
          </v-app-bar>
      <v-spacer></v-spacer>

      <v-app-bar-nav-icon class="navicon" color="black" light @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-navigation-drawer style="z-index: 99" dark class="navicon pt-12 pb-12" app v-model="drawer" absolute clipped hide-overlay>
          <div class="pl-10">
          <v-list-item
              class="mb-6"
          >
             <v-img                                
                :src="require('../assets/logo5.png')"                                
                contain                                
                class="imgs-2"
            />
          </v-list-item>
          <v-list-item href="/">HOME</v-list-item>
          <v-list-item @click="$vuetify.goTo('#services')">SERVICES</v-list-item>
          <v-list-item @click="$vuetify.goTo('#roadmap')">ROADMAP</v-list-item>
          <v-list-item @click="$vuetify.goTo('#product')">PRODUCT</v-list-item>
          </div>
      </v-navigation-drawer>

      <v-btn href="https://mesyamuddin.com" target="_blank" variant="outlined" rounded="xl" dark>
         Owner 
      </v-btn>
    </v-app-bar>

</template>


<script>
import Navbar from './Navbar.vue';


export default {
    name: "Nav",
    components: {
        Navbar
    },
    data: () => ({
        drawer: false,
        activeTabIndex: 0,
        // items: [
        //     {title: 'Home', icon: 'mdi-view-dashboard'},
        //     {title: 'Services', icon: 'mdi-image'},
        //     {title: 'Product', icon: 'mdi-help-box'},
        //     {title: 'Roadmap', icon: 'mdi-help-box'},
        // ]
        items: [                                  
            ["mdi-home-outline", "services", "#services"],                                
            ["mdi-home-outline", "roadmap", "#roadmap"],                                
            ["mdi-home-outline", "product", "#product"],                                
        ], 
    }),
    props: {
        color: String,
        flat: Boolean,
    },

}
</script>

<style>

@media (max-width: 600px) {
/* Styles for screens smaller than 600px */

}

@media (min-width: 601px) and (max-width: 960px) {
/* Styles for screens between 601px and 960px */
    .imgs-2 {

    }


}
</style>
