import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';

Vue.use(Vuetify);

export default new Vuetify({

    icons: {
        iconfont: 'fa' || 'md' || 'mdi'
    },
    theme: {
        themes: {
            light: {
              colors: {
                primary: '#1867c0',
                secondary: '#5CBBF6',
                tertiary: '#E57373',
                accent: '#005CAF',
                quarternary: '#B0D1E8',
                'surface-bright': '#fafafa',
              },
            },
            dark: {
              background: '#111111',
              colors: {
                primary: '#2196F3',
                secondary: '#424242',
                tertiary: '#E57373',
                accent: '#FF4081',
                quarternary: '#B0D1E8',
                'surface-bright': '#474747',
              },
            },
            blackguard: {
              dark: true,
              colors: {
                background: '#0f0c24',
                primary: '#e7810d',
                surface: '#1e184a',
                'on-surface-variant': '#4c219e',
                info: '#9c27b0',
                accent: '#FF4081',
                success: '#84b38a',
                'surface-bright': '#362b89',
              },
              variables: {
                'theme-code': '#15123d',
              },
            },
        }
      }
    }

);
