<template>
  <!-- <v-app :style="{background: $vuetify.theme.themes.dark.background}"> -->
  <v-app>
    <Navbar/>
    <Header/>
    <Body />
    <SBody />
    <Footers />
  </v-app>
</template>

<script>
import Navbar from '../components/Navbar';
import Header from '../components/Header';
import Body from '../components/Body';
import SBody from '../components/SBody';
import Footers from '../components/Footers';

  export default {
    name: 'HomeView',

    components: {
      Navbar,
      Header,
      Body,
      SBody,
      Footers,
    },
  }
</script>

