<template>
  <v-app id="inspire" fixed>

    <Nav />

    <v-main class="bg-grey-lighten-3" style="padding: 0">
        <div style="position: absolute; z-index: 50;" class="textheader"> 
            <h2>Empowering <label style="background-color: orange; padding: 10px;">Dreams</label>, One Freelance Project at a Time.</h2>
        </div>
        <v-carousel height="100vh" show-arrows-on-hover>
          <v-carousel-item
            src="https://i.ibb.co/GpBcHBF/bano2.png"
            cover
          ></v-carousel-item>
        </v-carousel>
    </v-main>

    <v-item-group 
        id="services"
        class="pelangi"
        style="background: linear-gradient(-90deg, rgba(192,36,177,1) 0%, rgba(193,127,0,1) 100%, rgba(9,9,121,1) 460%);"
    >
        <v-container >
        <v-row>
        <v-col
          v-for="n in 1"
          :key="n"
          cols="12"
          md="12"
        >
          <v-item v-slot="{ isSelected, selectedClass, toggle }">
              <div
                class="ma-14 text-h3 flex-grow-1 text-right offer"
              >
                  <v-text class="white--text offer-2">We offer services</v-text>
              </div>
          </v-item>
        </v-col>
      </v-row>

        </v-container>
    </v-item-group>


    <v-item-group>
        <v-container fluid class="pa-0">
        <v-row no-gutters>
        <v-col
          cols="12"
          md="4"
        >
            <v-item>
                <v-card
                  :class="['fill-width','d-flex align-center', selectedClass]"
                  class="rounded-0 blue justify-center"
                  dark
                  height="300"
                >
                    <v-img
                        src="https://i.ibb.co/KjMrrCc/Mask-group-1.png"
                        height="100%"
                        class="grey darken-4 img1"
                        cover
                    >
                      <div
                        color="#FFFFFF"
                        class="text-h5 flex-grow-1 text-center"
                      >
                        <div class="mb-3" style="margin-top: 17vh;">
                            <!-- <v-icon size="40" color="#FFFFFF" src="assets/gd.png"></v-icon> -->
                             <v-img                                
                                :src="require('../assets/gd.png')"                                
                                contain                                
                                height="50"
                            />

                        </div>
                        <v-text >Graphic Designer</v-text>
                      </div>
                    </v-img>
                </v-card>
              </v-item>
            </v-col>
        <v-col
          cols="12"
          md="4"
        >
            <v-item>
                <v-card
                  :class="['fill-width','d-flex align-center', selectedClass]"
                  class="rounded-0 blue justify-center"
                  dark
                  height="300"
                >
                    <v-img
                        src="https://i.ibb.co/ZNtHRtb/Mask-group-2.png"
                        height="100%"
                        class="grey darken-4 img1"
                        cover
                    >
                      <div
                        color="#FFFFFF"
                        class="text-h5 flex-grow-1 text-center"
                      >
                        <div class="mb-3" style="margin-top: 17vh;">
                            <!-- <v-icon size="40" color="#FFFFFF">fas fa-dragon</v-icon> -->
                             <v-img                                
                                :src="require('../assets/ud.png')"                                
                                contain                                
                                height="50"
                            />
                        </div>
                        <v-text >UIUX Designer</v-text>
                      </div>
                    </v-img>
                </v-card>
              </v-item>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
            <v-item>
                <v-card
                  :class="['fill-width','d-flex align-center', selectedClass]"
                  class="rounded-0 blue justify-center"
                  dark
                  height="300"
                >
                    <v-img
                        src="https://i.ibb.co/D1JZHXz/Mask-group-3.png"
                        height="100%"
                        class="grey darken-4 img1"
                        cover
                    >
                      <div
                        color="#FFFFFF"
                        class="text-h5 flex-grow-1 text-center"
                      >
                        <div class="mb-3" style="margin-top: 17vh;">
                            <!-- <v-icon size="40" color="#FFFFFF">fas fa-dragon</v-icon> -->
                             <v-img                                
                                :src="require('../assets/mm.png')"                                
                                contain                                
                                height="50"
                            />
                        </div>
                        <v-text >Multimedia</v-text>
                      </div>
                    </v-img>
                </v-card>
              </v-item>
            </v-col>
        <v-col
          cols="12"
          md="4"
        >
            <v-item>
                <v-card
                  :class="['fill-width','d-flex align-center', selectedClass]"
                  class="rounded-0 blue justify-center"
                  dark
                  height="300"
                >
                    <v-img
                        src="https://i.ibb.co/LJCkkHt/Mask-group.png"
                        height="100%"
                        class="grey darken-4 img1"
                        cover
                    >
                      <div
                        color="#FFFFFF"
                        class="text-h5 flex-grow-1 text-center"
                      >
                        <div class="mb-3" style="margin-top: 17vh;">
                            <!-- <v-icon size="40" color="#FFFFFF">fas fa-dragon</v-icon> -->
                             <v-img                                
                                :src="require('../assets/vg.png')"                                
                                contain                                
                                height="50"
                            />
                        </div>
                        <v-text >Videography</v-text>
                      </div>
                    </v-img>
                </v-card>
              </v-item>
            </v-col>
            <v-col
                cols="12"
                md="8"
            >
            <v-item>
                <v-card
                  :class="['fill-width','d-flex align-center', selectedClass]"
                  class="rounded-0 blue justify-center"
                  dark
                  height="300"
                >
                    <v-img
                        src="https://i.ibb.co/1JrktW0/Mask-group-4.png"
                        height="100%"
                        class="grey darken-4 img1"
                        cover
                    >
                      <div
                        color="#FFFFFF"
                        class="text-h5 flex-grow-1 text-center"
                      >
                        <div class="mb-3" style="margin-top: 17vh;">
                            <!-- <v-icon size="40" color="#FFFFFF">fas fa-dragon</v-icon> -->
                             <v-img                                
                                :src="require('../assets/uxc.png')"                                
                                contain                                
                                height="50"
                            />
                        </div>
                        <v-text >UIUX Designer & Code</v-text>
                      </div>
                    </v-img>
                </v-card>
              </v-item>
            </v-col>
      </v-row>
        </v-container>
    </v-item-group>
  </v-app>
</template>


<script>
import Nav from './Nav.vue';


  export default {
      name: "Navbar",

      components: {
        Nav,
      },

      data: () => ({
        drawer: false,
        activeTabIndex: 0,
        items: [
            {title: 'Home', icon: 'mdi-view-dashboard'},
            {title: 'Services', icon: 'mdi-image'},
            {title: 'Product', icon: 'mdi-help-box'},
            {title: 'Roadmap', icon: 'mdi-help-box'},
        ]
      }),
      
  }
</script>

<style>
    @font-face {
        font-family: myFont;
        src:url(../assets/Impact.ttf);
    }

    h2 {
        font-family: myFont;
    }

    .imgs {
        height: 200px;
        margin-left: -200px;
    }

 .img1:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
 }
 .t1 {
    margin-top: 100px;
 }

@media (max-width: 600px) {
/* Styles for screens smaller than 600px */
    .my-row {
        margin-top: 2vh;
        margin-right: 1vh;
    }

    .hexa{
        display: none;
    }

    .imgs {
        height: 150px;
        position: absolute;
        margin-left: -10px;
    }

    .caro {
        height: 300px;
    }

    .pelangi {
        height: 20vh;
    }

    .offer {
        width: 100vw;
        padding-right: 32vw;
    }

    .offer-2 {
        font-size: 4vh; 
        width: 100vw;
    }

    .textheader {
        margin-top: 20vh;
        margin-left: 13vw;
        margin-right: 13vw;
        text-align: center;
    }

}

@media (min-width: 601px) and (max-width: 960px) {
/* Styles for screens between 601px and 960px */
    .my-row {
        margin-top: 2vh;
    }

    .hexa{
        display: none;
    }

    .imgs {
        height: 150px;
        margin-left: -150px;
    }

    .textheader {
        margin-top: 20vh;
        margin-left: 13vw;
        margin-right: 13vw;
        text-align: center;
    }


}

@media (min-width: 961px) {
/* Styles for screens larger than 960px */
    .navicon {
        display: none;
    }

    .textheader {
        margin-top: 20vh;
        margin-left: 20vw;
        font-size: 23px;

    }

}
</style>
