<template>
    <v-container height="200" class="bg-grey pa-0 mt-16 mb-16" id="roadmap">
        <v-row>
            <v-col cols="12" md="6">
                <div class="mb-12 pa-6">
                    <v-row>
                    <v-text class="text-h4">
                        RoadMap
                    </v-text>
                        <v-text class="text-h7 mt-8">
                            A roadmap is a visual representation or plan that outlines the key steps, milestones, and objectives for a project, product, or any other endeavor. It provides a clear and organized way to communicate the strategic direction and timeline for achieving specific goals.
                        </v-text>
                        <v-btn class="rounded-xl mt-8" href="/roadmap" dark>
                             View More
                         </v-btn>
                    </v-row>
                </div>
            </v-col>
            <v-col cols="12" md="6">
                <div class="mb-12">
                    <v-img                                
                        :src="require('../assets/chart.gif')"                                
                        contain                                
                        height="350"
                    />

                </div>
            </v-col>
        </v-row>
        <v-row id="product">
            <v-col cols="12" md="6">
                <div class="mb-12">
                    <v-img                                
                        :src="require('../assets/product.jpg')"                                
                        contain                                
                        height="350"
                    />

                </div>
            </v-col>
            <v-col cols="12" md="6">
                <div class="mb-12 pa-6">
                    <v-row>
                    <v-text class="text-h4">
                       Product 
                    </v-text>
                        <v-text class="text-h7 mt-8">
                            Our user-friendly platform is tailored to meet the diverse needs of businesses across industries, providing a centralized hub for communication, scheduling, and task management.
                        </v-text>
                        <v-btn class="rounded-xl mt-8" href="/product" dark>
                             View More
                         </v-btn>
                    </v-row>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<style>

</style>
<script type="text/javascript">window.$crisp=[];window.CRISP_WEBSITE_ID="f1551729-8190-43b0-a3fe-3866fdd512b8";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();</script>
